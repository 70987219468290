import { useState, useEffect } from "react";
import { ParcelsC } from "../components/parcels";
import { BlogsData } from "../utils/content";
import "../App.scss";

function Main() {
  const [showBlogs, setShowBlogs] = useState(false);
  const [animatedBlogs, setAnimatedBlogs] = useState([]);

  useEffect(() => {
    if (showBlogs) {
      // Create staggered animation by showing blogs one by one
      const timer = [];
      BlogsData.forEach((_, index) => {
        timer[index] = setTimeout(() => {
          setAnimatedBlogs((prev) => [...prev, index]);
        }, 200 * (index + 1));
      });

      return () => timer.forEach((t) => clearTimeout(t));
    } else {
      setAnimatedBlogs([]);
    }
  }, [showBlogs]);

  return (
    <div className="page-container">
      {/* Background particles */}
      <div className="background-particles">
        <ParcelsC opacity={0.4} Hover={false} />
      </div>

      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1 className="hero-title">Explonis</h1>

          <p className="hero-subtitle">
            Web development studio with a passion for tech
          </p>

          <div className="hero-buttons">
            <button
              className="elegant-button"
              onClick={() => setShowBlogs(!showBlogs)}
            >
              {showBlogs ? "Hide Blogs" : "Check Blogs"}
            </button>
            <a
              href="https://mikemajdalani.com/contact"
              className="elegant-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us
            </a>
          </div>

          <div
            className={`elegant-blogs-container ${showBlogs ? "active" : ""}`}
          >
            <div className="elegant-blogs-list">
              {BlogsData.map((blog, index) => (
                <a
                  href={blog.link}
                  key={index}
                  className={`elegant-blog-item ${
                    animatedBlogs.includes(index) ? "visible" : ""
                  }`}
                >
                  <div className="blog-content-wrapper">
                    <h3 className="elegant-blog-title">{blog.name}</h3>
                    <p className="elegant-blog-excerpt">{blog.content}</p>
                  </div>
                  <span className="read-more-arrow">→</span>
                </a>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Main;
