export const BlogsData = [
  {
    name: "Web Dev in Lebanon",
    picture: "/blogs/webdev1.jpg",
    content:
      "Are you looking for Web developers in Lebanon? If yes, then this blog will be your perfect landing destination. Explore it now!",
    label: "",
    link: "/web-development-in-lebanon",
  },
  {
    name: "How to create a website?",
    picture: "/blogs/webdev4.jpg",
    content:
      "Are you looking to learn how to create your own website in Lebanon? this article will show you how!",
    label: "",
    link: "/how-to-create-your-own-website",
  },
  {
    name: "Mobile App Dev in Lebanon",
    picture: "/blogs/mobiledev1.jpg",
    content:
      "Are you looking for a mobile app developer in Lebanon? check out this blog that shows you everything you need to know about mobile apps",
    label: "",
    link: "/mobile-app-development-in-lebanon",
  },
];
